import { useCallback } from 'react';

import { useTracking } from '../../core/hooks/useTracking';
import type { AppTrackingItem } from '../types/trackingItem';
import { convertAppTrackingItemToTrackingItem } from '../types/trackingItem';

export function useAppTracking() {
  const { track } = useTracking();
  const appTrack = useCallback(
    (appTrackingItem: AppTrackingItem) => {
      track(convertAppTrackingItemToTrackingItem(appTrackingItem));
    },
    [track],
  );

  return {
    track: appTrack,
  } as const;
}
