import { useAtom } from 'jotai/index';
import { useCallback } from 'react';

import { trackersAtom } from '../atoms/trackers.atom';
import type { TrackingItem } from '../types/trackingItem';

export function useTracking() {
  const [trackers] = useAtom(trackersAtom);
  const track = useCallback(
    (trackingItem: TrackingItem) => {
      trackers.forEach((tracker) => tracker.track(trackingItem));
    },
    [trackers],
  );

  return {
    track: track,
  } as const;
}
